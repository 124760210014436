import { useEffect, useRef, useState } from "react";
import { ICONS } from "../../data/iconClasses";
import { STYLES } from "../../data/styleClasses";
import { createFormObject } from "../../utils/utils";

export const Activity = ({data, actions}) => {
    const activityForm= useRef();
    const {activity, highlight, id} = data;
    const [active, setActive] = useState((highlight==1)?true : false);
    const {handleDelete,handleEdit, handleHighlight} = actions;
    const [editable,setEditable] = useState(false);
    const clickDelete = async (e)=>{
        e.preventDefault();
        await handleDelete(createFormObject(new FormData(activityForm.current)));
    }

    const clickEdit = async (e)=>{
        e.preventDefault();
        setEditable(curr=>!curr);
        await handleEdit(createFormObject(new FormData(activityForm.current)));
    }

    const clickHighlight = async (e)=>{
        // e.preventDefault();
        console.log(e.target.checked);
        // setActive(curr=>!curr);
        // console.log(id, active);
        await handleHighlight(createFormObject(new FormData(activityForm.current)));
    }
    // console.log(id, active);
    // useEffect(()=>{console.log(active)},[active])
    return (
        <li className={active? 'action' : ''} id={id}>
            <form ref={activityForm}>
                <input name={"id"} type="hidden" defaultValue={id}/>
                <input name={"highlight"} type="checkbox"  defaultChecked={active} onChange={clickHighlight}/>
                <textarea rows={4} name="activity" disabled={!editable} defaultValue={activity}/>
                {editable?
                    <button onClick={clickEdit} className={STYLES.formButton}>{ICONS.save}</button> :
                    <button onClick={(e)=>{e.preventDefault();setEditable(curr=>!curr)}} className={STYLES.formButton}>{ICONS.edit}</button>}
            {/* <button onClick={clickHighlight} className={STYLES.formButton}>{ICONS.expand}</button> */}
            <button onClick={clickDelete} className={STYLES.negativeButton}>{ICONS.delete}</button>
            </form>
        </li>
    )
}