import { useEffect, useState } from "react";
import { criterion, useFetch } from "../../Hooks/useFetch"
import { FormComponent } from "../../components/form";
import { createFormObject } from "../../utils/utils";
import { STYLES } from "../../data/styleClasses";

const responsibilityFormFields = [
    {label: "Responsibility", name:"description", type:"text"}
]
export const ResponsibilitiesPage =() => {
    const api = useFetch();
    const [responsibilities, setResponsibilities] = useState([]);
    const [selected, setSelected] = useState([]);

    const apiOptions = {
        endpoint:'/v1/pageData/responsibilities',
    }

    const Responsibility = ({item, dataSet}) => {
        const {description, id} = item;
        const checked = dataSet.has(id);
        const label = description;

        return (
        <div className={STYLES.cardItem}>
            <label htmlFor={description}>{label}</label><input id={id} name={description} type={"checkbox"} defaultChecked={checked} value={JSON.stringify({...item})}/>
        </div>)
    }

    const initialize = async () => {
        const {data,status} = await api.apiGet(apiOptions);
        console.log(data);
        setResponsibilities(data);
    }
    const addResponsibility = async (inputs) => {
        console.log('add')
        // const inputs = createFormObject(new FormData(e.target));
        // console.log(inputs);
        const submitOptions = {
            endpoint:'/v1/pageData/responsibilities',
            newValues:[
                inputs
            ]
        }
        const {data, status} = await api.apiInsert(submitOptions);
        setResponsibilities(curr=>([...curr,{...inputs,id:data.ID}]))
        // console.log(data);
    }

    const deleteResponsibility = async (inputs) => {
        const deleteIds = inputs.map(input => [criterion('id','eq',input.id)]);
        const submitOptions = {
            endpoint:'/v1/pageData/responsibilities',
            filterCriteria: deleteIds

        }
        const {data, status} = await api.apiDeleteMultiple(submitOptions);        
        setResponsibilities(curr=>(curr.filter(item=>!inputs.map(item=>item.id).includes(item.id))))

    }
    useEffect (()=>{
        initialize()
    },[])

    return (
        <div>
            Responsibilities
            <FormComponent selected={selected} data={responsibilities} handleDelete={deleteResponsibility} handleSubmit={addResponsibility} childElement={Responsibility} formFields={responsibilityFormFields}/>
            {/* <ul>
                {responsibilities.map(res=>(
                    <li>{res.description}</li>
                ))}
            </ul> */}
        </div>
    )
}