import { useParams } from "react-router-dom"
import { criterion, useFetch } from "../../Hooks/useFetch";
import { useEffect, useRef, useState } from "react";
import { createFormObject, isObjEmpty } from "../../utils/utils";
import { STYLES } from "../../data/styleClasses";
import { ICONS } from "../../data/iconClasses";
import { ENDPOINTS, OPERATORS } from "../../data/endpoints";
import { Dialog } from "../../components/dialog";
import { Skills } from "../skills";
import { TextArea } from "../spacing";
import { Qualification } from "./qualification";
import { TemplateSelector } from "./resumeSelection";

const STATUS = {
    Unsubmitted: 0,
    Pending:1,
    Rejected:2,
    Interview:3,
    "Pending Response":4
}


export const Application = () => {
    const {id} = useParams();
    const api = useFetch();
    const [application, setApplication] = useState();
    const [templates, setTemplates] = useState();
    const [activeTemplate, setActiveTemplate] = useState(null);
    const [qualifications, setQualifications] = useState();
    const [skills, setSkills] = useState();
    const skillRef = useRef();
    
    const APPLICATION = {
        endpoint:ENDPOINTS.ApplicationPage,
        filterCriteria: [["id",id]]
    }
    
    const handleDisplayResume = (resume) => {
        setActiveTemplate(resume);
    };

    const initialize = async () => {

        const {data:{resumes, application}, status} = await api.apiGet(APPLICATION);       
            console.log(application);
            setApplication(application[0]);
            setQualifications(application[0].qualifications);
            // console.log(application[0].qualifications);
            console.log(application[0].resume_id)
            setTemplates(resumes);
            if(isObjEmpty(application[0].resume)){
                console.log('is empty');
                return
            }
            
            const activeResume = resumes.find(resume=>resume.id==application[0].resume_id)
            handleDisplayResume(activeResume)
    }

    useEffect(()=> {
        initialize();
    },[])

    const templateActions = {
        select:  async (e) => {
            const resumeId = e.currentTarget.value;
        // console.log(resumeId);
            const resume = templates.find(resume=>resume.id==resumeId)
            console.log(resume);
            handleDisplayResume(resume);  
        },
        delete: async (e) => {
            // const resumeId = activeTemplate.resume.id;
            const inputs = {"id":application.id,"resumes_id": null};
            console.log(inputs);
            setActiveTemplate(null)
            setApplication(curr=>({...curr,resume:null,resume_id:null}))
        },
        save: async (e) => {
            const resumeId = activeTemplate.id;
            const inputs = {":id":resumeId};
            const apiOptions = {
                endpoint:ENDPOINTS.ApplicationPage,
                updateCriteria:[['id',id]],
                updateValues:[{resumes_id:resumeId}]
            }
            const {data, status} = await api.apiUpdateV1(apiOptions);
            console.log(data, status);
            setApplication(curr=>({...curr,resume:activeTemplate,resume_id:activeTemplate.id}));
        }
    }

    // const handleDeselectTemplate = async (e) => {
    //     const resumeId = activeTemplate.resume.id;
    //     const inputs = {":id":resumeId};
    //     console.log(inputs);
    //     setActiveTemplate(null);
        // const {data, status} = api.execute({endpoint:ENDPOINTS.unlinkTemplate,criteria:[[criterion("id","=",resumeId)]]});
        // if (status==200) {
        //     setActiveTemplate(null);
        // }
        // initialize();

    // const handleSelectTemplate = async (e) => {
    //     const resumeId = e.currentTarget.value;
    //     // console.log(resumeId);
    //     const resume = templates.find(resume=>resume.id==resumeId)
    //     console.log(resume);
    //     handleDisplayResume(resume);        
    // }

    const handleChange = async (e) => {
        const {name, value} = e.target;
        
        const data = await api.execute({endpoint:ENDPOINTS.testing,inputs:{[name]:value}, criteria:[[criterion("id","=",id)]]});
        console.log(data);
    }

    if (isObjEmpty(application)){
        return (<div>Loading</div>)
    }

    const qualificationSubmit = async (e) => {
        e.preventDefault();
        const apiOptions = {
            endpoint:"/v1/objects/qualifications"
            // endpoint:ENDPOINTS.ApplicationPage
        };
        const form = new FormData(e.target);
        const quals = form.get("qualification");
        
        const qualificationArray = quals.split("\n");
        apiOptions.newValues =
        qualificationArray.map(qual=>(
            {
                qualificationType: 0,
                applicationId:id,
                qualification: qual
            }
        ));
        
        const {data} = await api.apiInsert(apiOptions);
        console.log(data);
    
    }

    const openDialog = (e)=> {
        skillRef.current.showModal();
    
    }

    return (
        <div>
            Application Details <span className="block">{application.title}</span>

            <p><a href="/applications/list">Back to applications...</a></p>
            <p><a href={`/applications/view/${application.uuid}`} target="_blank">View Application</a></p>
            
            {/* Status Selector */}
            <label htmlFor="status">Status:</label><select onChange={handleChange} name="status" className={STYLES.form}>
                {Object.entries(STATUS).map(([key,value], index)=>(
                    
                    <option value={value}>{key}</option>
                ))}
                </select>        

            {Array.isArray(activeTemplate?.resume?.jobs)? 
                activeTemplate.resume.jobs.map(({title, organization, description})=>(
                    <li>
                        <p>{title}</p>
                        <p>{organization}</p>
                    </li>
                )) :
                <></>
            }
                <TemplateSelector templates={templates} active={activeTemplate} saved={application.resume_id==activeTemplate?.id} actions={templateActions}/>

            <section>
            <button onClick={openDialog}>Add Skill</button>
            <dialog ref={skillRef}>
                    <button onClick={(e)=>skillRef.current.close()} className={STYLES.negativeButton}>{ICONS.close}</button>
                    <Skills/>
                </dialog>
                {/* <TextArea/> */}
                <form onSubmit={qualificationSubmit}>
                    <label htmlFor="qualification">Qualifications</label><textarea rows={4} name="qualification" className={STYLES.input}/>
                    <button type="submit" className={STYLES.submitButton}>{ICONS.add}</button>
                </form>
                
                <ul>
                    {Array.isArray(qualifications)?
                        qualifications.map((qualification,i)=>(
                        <li>
                            <Qualification key={`qualification-${qualification.id}`} applicationId={id} qual={qualification} skills={skills}/>  
                        </li>
                    )) :
                    <></>
                }
                </ul>
                
            </section>
        </div>
    )
}

