import { useEffect, useRef, useState } from "react";
import { ICONS } from "../data/iconClasses";
import { PAGES, env } from "../data/pages";
import { Menu } from "../HOC/Menu/menu";
import { click } from "@testing-library/user-event/dist/click";

export const Header = () => {
    const [expanded, setExpanded] = useState(false);
    const environment = env[process.env.NODE_ENV];
    const headerRef = useRef(null);

    // const expand =() => {
    //     setExpanded(curr=>!curr);
    // }

    // const loseFocus = (e) => {
    //     // console.log(expanded)
    //     if(expanded==true && !headerRef.current.contains(e.target)) {
    //         setExpanded(false)
    //     };
    // }

    const expand = () => {
        setExpanded(true);
        document.addEventListener('click', loseFocus);
    };

    const loseFocus = (e) => {
        if (!headerRef.current.contains(e.target)) {
            setExpanded(false);
            document.removeEventListener('click', loseFocus);
        }
    };

    // useEffect( (e)=>{    
    //     document.addEventListener('click', loseFocus);
        
    //     return ()=> {
    //         document.removeEventListener('click',loseFocus);
    //     }
    // },[])

    return (
        <header className={"secondary o-hidden"} ref={headerRef}    > 
        {expanded? 
            <>
            <button className="height-spacing inline-margin block" onClick={expand}>{ICONS.close}</button>
            <Menu links={PAGES.filter(page=>page.environment>=[environment] && page.type!=1)}/> 
            </>:
            <button className="height-spacing inline-margin block" onClick={expand}>{ICONS.action}</button>
        }
        
        </header>
    )
}