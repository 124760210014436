import { ICONS } from "../../data/iconClasses"
import { STYLES } from "../../data/styleClasses"


export const TemplateSelector = ({templates=[], active, saved, actions={}}) => {
    
    return (
    active?
        <div>
            <a className={STYLES.formButton} href={"/#"} target="_blank">{ICONS.edit}</a>
            <button className={STYLES.formButton} onClick={actions.delete}> {ICONS.delete}</button>
            {saved? null :<button className={STYLES.formButton} onClick={actions.save}>{ICONS.save}</button>}
        </div>:
        <>
            <select className={STYLES.form} onChange={actions.select}>
                <option value={-1}>Choose:</option>
                {templates.map(({name, id})=><option value={id}>{name}</option>)}
            </select>
            <button onClick={actions.select} className={STYLES.formButton}>{ICONS.add}</button>
        </>
    )
}