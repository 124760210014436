import { useState } from "react";

export const CSSTesting = () => {
    const [number, setNumber] = useState()
    const [color, setColor] = useState('red');
    const getData =(e) => {
        const value = e.target.value;
        value>75 ?
            setColor('green') :
            value>50 ?
                setColor('yellow') :
                setColor('red');
                
        setNumber(curr=>(value));    
    }
    return (
        <div>
            <input onChange={getData} type="range"/>
            <p>{number}</p>
            {/* <div className="outer-container"> */}
                <div className="container">
                    <div className="circle">
                        <div className={"level"} style={{width: `${number*5}%`, backgroundColor:color}}></div>    
                    </div>
                    <div className="circle">
                        <div className={"level"} style={{width: `${Math.max((number - 20) * 5,0)}%`, backgroundColor:color}}></div>
                    </div>
                    <div className="circle">
                    <div className={"level"} style={{width: `${Math.max((number - 40) * 5,0)}%`, backgroundColor:color}}></div>
                    </div>
                    <div className="circle">
                    <div className={"level"} style={{width: `${Math.max((number - 60) * 5,0)}%`, backgroundColor:color}}></div>
                    </div>
                    <div className="circle">
                    <div className={"level"} style={{width: `${Math.max((number - 80) * 5,0)}%`, backgroundColor:color}}></div>
                    </div>
                    {/* <div className={"level"} style={{width: `${number}%`, backgroundColor:'green'}}></div>     */}
                </div>
            {/* </div> */}
            
        </div>
    
    )
}