export const STYLES = {
    // Form and input styles...
    form: "padded action shadow",
    input: "block action x-90",
    checkbox: "",
    // States...
    active: "action",
    disabled: "disabled",
    inactive:"secondary",

    // Button styles...
    formButton: "inline-margin inline-spacing flex-grow action text-centered height-padding",
    negativeButton: "inline-margin flex-grow inline-spacing negative text-centered height-padding",
    submitButton: "right block inline-margin inline-spacing rounded tertiary height-spacing height-padding",
    floatingButton: "action btn-standard",
    
    // Layout styles...
    spaced: "inline-margin inline-spacing height-spacing height-padding",
    wideFlexbox: "flex hide flex-stretched",
    // Component-specific styles...
    menuItem:"nav-item",
    cardItem:"flex hide flex-stretched height-spacing",
    
    scroll: "scroll",

    // list styles
    listButton: "secondary",
    list: "primary bordered"
}