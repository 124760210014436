import { useRef, useState } from "react";
import { useFetch } from "../../Hooks/useFetch";
import { STYLES } from "../../data/styleClasses";
import { createFormObject } from "../../utils/utils";
import { Activity } from "./activity";
import { FormComponent } from "../../components/form";
import { ENDPOINTS } from "../../data/endpoints";


export const Qualification = ({qual, skills, applicationId}) => {
    const api = useFetch();
    const [qualification,setQualification] = useState(qual);
    const [linkedSkill, setLinkedSkill] = useState(qual.skills);
    const [linkedActivity, setLinkedActivities] = useState(qual.activities);
    
    const skillSelect = useRef();

    const refreshSkills = async () => {

    }
    const refreshActivities = async () => {
            
        

    }
    const updateQualification = async (e) => {
        const input = {qualificationId: qualification.id, skillId: parseInt(e.target.value)};
        
    }


    const handleActivity = async (e) => {
        e.preventDefault();
        const inputs = createFormObject(new FormData(e.target));
        inputs.qualificationId = qualification.id;
        const apiOptions={
            // endpoint:"/v1/objects/qualificationActivity",
            endpoint:"/v1/objects/qualificationActivity",
            newValues:[inputs]
        }
        const {data,status} = await api.apiInsert(apiOptions)
        if(status==200){
            setLinkedActivities(curr=>[...curr,{id:data[0].ID,...inputs}])
            e.target.reset()
        }
        // refreshActivities();
    }

    const activityActions = {
        handleDelete:async (data)=> {
            const apiOptions = {
                endpoint:ENDPOINTS.QualificationActivities,
                filterCriteria:[["id",data.id]]
            }
            console.log(apiOptions)
            const {data:apiData, status} = await api.apiDelete(apiOptions);
            console.log(apiData)
            if (status==200){
                setLinkedActivities(curr=>curr.filter(item=>item.id!=data.id))
            }
        },

        handleEdit: async (data)=> {
            console.log({edit:data});
            const apiOptions = {
                endpoint:ENDPOINTS.QualificationActivities,
                updateCriteria:[["id",data.id]],
                updateValues:[{"activity":data.activity}]
            }
            const {data:apiData, status} = await api.apiUpdateV1(apiOptions);
            console.log(apiData);
        },

        handleHighlight: async (data)=> {
            const apiOptions = {
                endpoint:ENDPOINTS.QualificationActivities,
                updateCriteria:[["id",data.id]],
                updateValues:[{"highlight":data.highlight?1 : 0}]
            }
            // console.log(apiOptions);
            const {res, data:apiData,status} = await api.apiUpdateV1(apiOptions);
            console.log(res);
        },
    }

    return (
        <article>           
            <h1>{qualification.qualification}</h1>
            <form onSubmit={handleActivity} id={"activityForm"}>
                <label>Add Activity:</label><input autoComplete={'off'} name={"activity"} className={STYLES.input}/>
            </form>
            
            <ul className={"secondary"}>
                {Array.isArray(linkedActivity)?
                    linkedActivity.map(activity=>(
                        <Activity actions={activityActions} data={activity}/>
                    )):
                    <></>
                }
            </ul>
            </article>
                            
                            
    )
}
