import { Rating } from "../../components/widgets/ratings/ratings";

export const SkillCard = ({data}) => {
    const {skill, level, type } = data;
    // console.log(data);
    return (
        <div>

            <p>{skill}</p>
            {/* <Rating score={level}/> */}
            <p><a className={'highlight-text links'} href="/#">Read More</a></p>
        </div>
    )
}