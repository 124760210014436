import { act } from "react-dom/test-utils";
import { ICONS } from "../../data/iconClasses";
import { useState } from "react";

export const JobCard = ({data}) => {
    const [collapsed, setCollapsed] = useState(true);
    const {title, activities, description, start, end, organization} = data;
    const responsibilities = data.hasOwnProperty("responsibilities")? data.responsibilities : null;
    console.log(data);

    const collapse = (e) => {
        if(Array.isArray(activities)){
            setCollapsed(curr=>!curr)
        };
    }
    return (
        <div onClick={collapse} className="expandable bento-3">
            <div><p>{title} at {organization}: {" "} 
            {start} - {end}</p></div>
            <p>{description}</p>
            
            {/* <button onClick={collapse} className="collapse-button-y">{ICONS.add}</button> */}
            <div className={`collapsable-y ${collapsed? 'collapsed-y' : ''}`}>
            <ul>

                {Array.isArray(activities) && activities.map(({achievement})=>(
                    <li>{achievement}</li>
                ))}
            </ul>
            </div>
            
        </div>
    )
}