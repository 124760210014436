import { useEffect, useRef } from "react"
import { ICONS } from "../../data/iconClasses"
import { useFetch, URLQuery, criterion } from "../../Hooks/useFetch";
import { OPERATORS } from "../../data/endpoints";

const body = {
    ":organization":"Testing",
    ":link":"test.com",
    ":title":"Tester"
}

export const TestPage =() => {
    const api = useFetch();

    const testMethod = async (method) => {
        const criteria = [
            [criterion("id",OPERATORS.equalOrGreater,30),criterion("organization",OPERATORS.equal,"North Carolina")],
            [criterion("id",OPERATORS.lessThan,28),criterion("organization",OPERATORS.greaterThan,"BetaNXT")]
        ]
        
        const searchParams = URLQuery("id=4","test=testing");
        
        // const criteria = "testing";
        const res = await api.execute({method:method, endpoint:"/v1/objects/test"});
        // const res = await api.execute({method:method, endpoint:"/v1/objects/test",inputs:body});
        // const res = await api.execute({method:method, endpoint:"/v1/objects/test.php", searchParams:searchParams, criteria:criteria, encode:true});
        console.log(res);
    };

    const testPost = async () => {
        const options = {
            endpoint:"/v1/objects/test",
            newValues:[
                {organization:"New API", title:"Lead Tester", link:"google.com"}
            ]}
        const res = await api.apiInsert(options);
        console.log(res);
    }

    const testGet = async () => {
        const options = {
            endpoint: "/v1/objects/test",
            filterCriteria:[
                [criterion("id",OPERATORS.greaterThan,20)]
            ],
            complexQuery:true
        }
        const res = await api.apiGet(options)
        console.log(res);
    }

    const testDelete = async () => {
        const options = {
            endpoint: "/v1/objects/test",
            filterCriteria: [
                ["id",35]
            ],
            
            complexQuery:false
        }
        const res = await api.apiDelete(options);
        console.log(res);
    }

    const testUpdate = async () => {
        const options = {
            endpoint: "/v1/objects/test",
            newValues: [
                {title:"New Title", organization:"Google LLC"}
            ],
            filterCriteria: [
                [criterion("id",OPERATORS.greaterThan,35)]
                // ["id",34]
            ],
            
            complexQuery:true
        }
        const res = await api.apiUpdate(options);
        console.log(res.data);
    }
    return (
        <main>
        <section>
            <button onClick={()=>{testGet()}}>{"GET"}</button>
            <button onClick={()=>{testPost()}}>{"POST"}</button>
            <button onClick={()=>{testDelete()}}>{"DELETE"}</button>
            <button onClick={()=>{testUpdate()}}>{"UPDATE"}</button>
        </section>
        <section>

        </section>
        </main>)
}