import { ICONS } from "../../data/iconClasses";
import { createFormObject } from "../../utils/utils";
import { STYLES } from "../../data/styleClasses";

export const ActivityCard = ({activity}) => {
    
    return (
        <article>
            <span>{activity.achievement}</span>

        </article>
    )
}