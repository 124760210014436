import { useParams } from "react-router-dom"
import { criterion, useFetch } from "../../Hooks/useFetch";
import { ENDPOINTS, OPERATORS } from "../../data/endpoints";
import { STYLES } from "../../data/styleClasses";
import React, { useEffect, useReducer, useRef, useState } from "react";
import "./applicationView.css";
import { JobCard } from "./jobCard";
import { SkillCard } from "./skillCard";
import ResponsiveImage from "./ImgContainer";
import { ICONS } from "../../data/iconClasses";
import { getRandom, useArray } from "../../Hooks/useArray";


const HEADERS = {
    jobs:"Work History"
}

const NAVIGATION = {

}
export const ApplicationView = () => {
    const {uuid} = useParams();
    const api = useFetch();
    const pageEndpoint = ENDPOINTS.ApplicationView;
    const [pageData, setData] = useState();
    const sideRef = useRef();
    const [side,setSide] = useState(false);
    const [qualifications,setQualifications] = useState([]);
    const sectionRef = useRef({});
    // const [state, dispatch] = useReducer(reducer);

    const scrollToTarget = (ref) => {
        const targetElement = sectionRef.current[ref];
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      };

    const resumeSections = {
        jobs:JobCard,
        skills:SkillCard
    }
    const initialize = async () => {
        const initializeOptions = {
            endpoint: pageEndpoint,
            filterCriteria:[[criterion("uuid",OPERATORS.equal,uuid)]],
            complexQuery:true
        }

        const {data, status} = await api.apiGet(initializeOptions);
        
        setData(data.application[0]);
        const qualifications = [].concat(...data.application[0].qualifications.map(({activities})=>Object.values(activities)));
        setQualifications(getRandom(qualifications, 6));

    }
    useEffect(()=>{
        initialize();
    },[])

    if(!pageData){
        return (<div>Loading...</div>)
    }

    const collapse = (component) =>{
        // if(!component.current) {console.log(component); return}
        setSide(curr=>!curr);
    }

    return (
        <div id="page">
            <div className="">
                <nav className={`sticky collapsable-x ${side? '': 'collapsed-x'}`} id="sidebar">
                    <button onClick={()=>collapse(sideRef)}>{ICONS.action}</button>
                    <ul  ref={sideRef}>
                        <li><button onClick={()=>scrollToTarget('jobs')}>Work History</button></li>
                        <li><button onClick={()=>scrollToTarget('skills')}>Skills</button></li>
                    </ul>
                </nav>
            </div>
            <main>
                <article id={"headline"}>
                    <ResponsiveImage imageUrl={"https://www.stevenwsosebee.com/images/team.jpg"} minHeight={'400px'}/>
                    <h1>Value Statement</h1>
                    <p>{pageData.value}</p>
                </article>
                <article id="qualifications">
                <h1>Qualification Highlights</h1>
                <ul className="bento">
                    {qualifications.map(item=>          
                                    <li className="tertiary bento-2">
                                        {item.activity}
                                    </li>
                                )}
                </ul>
                </article>
                {Object.keys(pageData.resume[0].resume).map((section,i)=>(
                    <article ref={ref=>sectionRef.current[section]=ref}>
                        <h1>{HEADERS[section]}</h1>
                    <ul className={(section=='skills')? "bento bento-small" : "bento"}>
                        {pageData.resume[0].resume[section].map(item=>(
                            React.createElement(resumeSections[section],{data:item})
                        ))}
                    </ul>
                    </article>
                ))}
                </main>
        </div>
        
    )
}