import { useRef } from "react"
import { FormComponent } from "../../components/form";

const jobFormFields = [
    {
        label:'Job Title',
        name:'title',
        type:'text'
    },
    {
        label:'Organization',
        name:'organization',
        type:'text'
    },
    {
        label:'Start',
        name:'start',
        type:'date'
    },
    {
        label:'End',
        name:'end',
        type:'date'
    }
]

export const FormTest = () => {

    return(
        <div>Hello</div>
    )
}