import { useState, useEffect } from "react";
import "./ratings.css";
import './star.svg';

// "/applicationresume/:uuid"

const COLORS = {
    low: 'red',
    med: 'yellow',
    high:'yellow',
    advanced: 'green',
    master:'green'
};

const LEVELS = {
    low:"Novice",
    med:"Proficient",
    high:"Experienced",
    advanced:"Advanced",
    master:"Expert"
}

	
export const Rating = ({score}) => {
    const [color, setColor] = useState();
    const [classes, setClasses] = useState();
    const [fill, setFill] = useState();
    useEffect(()=>{

        switch (score) {
            case LEVELS.low:
                
                setColor(COLORS.low);
                setFill(20);
                break;
            case LEVELS.med:
                
                setColor(COLORS.high);
                setFill(40);
                break;
            case LEVELS.high:
                
                setColor(COLORS.high);
                setFill(60);
                break;
            case LEVELS.advanced:
                
                setColor(COLORS.advanced);
                setFill(80);
                break;
            case LEVELS.master:
                
                setColor(COLORS.master);
                setFill(100);
                break;
            default:
                console.log('default');
                setColor(LEVELS.low)
                  
        }

        score=LEVELS.master ?
            setClasses('mastery') : setClasses('');
    },[score, fill]);

    const ratingClasses = `rating ${classes}`;

    const radius = 20; // Radius of the circle
    const circumference = 2 * Math.PI * radius; // Circumference of the circle
    const progress = (100 - fill) / 100; // Calculate progress from percentage

    const circleStyle = {
        strokeDasharray: `${circumference} ${circumference}`,
        strokeDashoffset: progress * circumference,
        // stroke: color
    };
    console.log(progress);
    return (

        <div>
            <svg className="circle-container">
                <defs>
                <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: 'var(--progress-color' }} /> {/* Start color */}
          <stop offset="100%" style={{ stopColor: 'var(--action-color' }} /> {/* End color */}
        </linearGradient>
                </defs>
                <circle className="circle-background" cx={'50%'} cy={'50%'} r={radius}/>
                <circle className="circle-progress" cx={'50%'} cy={'50%'} r={radius} style={circleStyle}/>
            </svg>
        </div>
        

        // </div>

        // <div className="container">
        //     <div className={ratingClasses}>
        //         <div className={"level"} style={{width: `${fill*5}%`, 
        //         backgroundColor:color
        //         }}></div>    
        //     </div>
        //     <div className={ratingClasses}>
        //         <div className={"level"} style={{width: `${Math.max((fill - 20) * 5,0)}%`, 
        //         backgroundColor:color
        //     }}></div>
        //     </div>
        //     <div className={ratingClasses}>
        //         <div className={"level"} style={{width: `${Math.max((fill - 40) * 5,0)}%`, 
        //         backgroundColor:color
        //         }}></div>
        //     </div>
        //     <div className={ratingClasses}>
        //         <div className={"level"} style={{width: `${Math.max((fill - 60) * 5,0)}%`, 
        //         backgroundColor:color
        //         }}></div>
        //     </div>
        //     <div className={ratingClasses}>
        //         <div className={"level"} style={{width: `${Math.max((fill - 80) * 5,0)}%`, 
        //         backgroundColor:color
        //         }}></div>
        //     </div>
        // </div>
        )
}