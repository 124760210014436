import './image.css';

const ResponsiveImage = ({ imageUrl, minHeight }) => {
    const imgClamp = `clamp(${minHeight},100%, )`;
  return (
    <div className="responsive-image-container" style={{ height:minHeight }}>
      <img src={imageUrl} alt="Responsive" className="responsive-image" />
    </div>
  );
}

export default ResponsiveImage;
