// import { useForm } from "../Hooks/useForm";
// export const Form = ({ name, id, ref, classes, formData, children}) => {
import React, { useReducer, useRef } from "react";
import { ICONS } from "../data/iconClasses";
import { STYLES } from "../data/styleClasses";
import { createFormObject } from "../utils/utils";


export const FormComponent = ({selected, data, handleSubmit, handleSelect, handleDelete, closeDialog, childElement, formFields})=>{
  const formRef = useRef();
  const dataSet = new Set (selected.map(item=>(item.id)));

  const formActions = (state, action) => {
    const inputs = Array.from(new FormData(formRef.current).entries()).map(item=>(JSON.parse(item[1])));
    switch (action.type) {
      case 'select':
        handleSubmit(inputs);
      case 'delete':
        handleDelete(inputs)
      default:
        return {action:'no action'};
        
    }
  }
  const [formInputs, formAction] = useReducer(formActions,null);

  const createItem = async (e) => {
    console.log('submit');
      e.preventDefault();
      const inputs = createFormObject(new FormData(e.target));
      await handleSubmit(inputs);
      // handleSubmit(curr=>([...curr,inputs]));
      // return inputs;
  }

  const formSubmit = async (e) => {
    e.preventDefault();
    const inputs = Array.from(new FormData(e.target).entries()).map(item=>(JSON.parse(item[1])));
    console.log(inputs);
    console.log(formInputs);
    // formInputs.action(inputs);
  }

  const selectItems = (e) => {
      formAction({type:'select'})
      // handleSubmit(curr => [...inputs]);
      if (closeDialog) {closeDialog()};
  }

  const deleteItems = (e) => {
    e.preventDefault();
    console.log('deleteItems')
    formAction({type:'delete'})
  }

  return (
  <div>
      <form onSubmit={createItem}>
        {formFields.map(({name,label,type})=>(
            <div>
                <label htmlFor={name}>{label}</label><input className={STYLES.input} name={name} type={type}/>
            </div>             
        ))}
        <button className={STYLES.floatingButton} value={"submit"}>{ICONS.add}</button>
        </form>
        <form ref={formRef} className={STYLES.spaced + ' base'}>
          {data.map(item=>(React.createElement(childElement,{item:item, dataSet:dataSet})))}
          <button type={"button"} onClick={selectItems} className={STYLES.floatingButton}>{ICONS.add}</button>
          <button type={"button"} onClick={deleteItems} className={STYLES.negativeButton}>{ICONS.delete}</button> 
        </form>
    </div>
                  )
}